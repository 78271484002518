<template>
	<div class="page">
		<div class="pageTitle">
			<div class="titleLeft" @click="$router.back()">
				<img class="iconBack" src="../../assets/img/back.png">
			</div>
			<div class="titleText" v-if="type==='header'">头部图片</div>
			<div class="titleText" v-if="type==='footer'">爪爪图片</div>
			<div class="titleText" v-if="type==='tail'">尾巴图片</div>
			<div class="titleRight"></div>
		</div>
		<div class="list">
			<img class="image" v-for="(item,index) in list" :key="index" :src="item"/>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				type:'',
				list:[],
			}
		},
		mounted(){
			
			this.type = this.$route.query.type
			this.list = JSON.parse(this.$route.query.list)
		},
		methods:{
			
		}
	}
</script>

<style scoped lang="scss">
.page{
	height: 100%;box-sizing: border-box;overflow: auto;padding-top:.92rem;
	.pageTitle{
		display: flex;position: fixed;height: .92rem;background-color: #fff;top:0;
		.titleLeft{
			width:1rem;
			.iconBack{
				height: .4rem;padding-top:.25rem;
			}
		}
		.titleText{
			width:5.5rem;font-size: .32rem;color:#333;font-weight: bold;padding-top:.22rem;
		}
		.titleRight{
			width:1rem;padding-top:.22rem;
		}
	}
	.list{
		margin:.1rem;background-color: #fff;
		.image{
			width:100%;
		}
	}
	
}	
</style>
